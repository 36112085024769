import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-09b0dcca")
const _hoisted_1 = { class: "main" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_public_menu = _resolveComponent("public-menu")!
  const _component_public_bottom = _resolveComponent("public-bottom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_main_header),
    _createVNode(_component_router_view),
    _createVNode(_component_public_menu),
    _createVNode(_component_public_bottom)
  ]))
}